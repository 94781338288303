
import {defineComponent, ref, reactive, computed, watch} from 'vue';
import topBar from '@/components/nav/topBar.vue';

export default defineComponent({
      name: 'Test',
      components: {
        topBar
      },
      setup() {
        const person = reactive({
          firstName: '东方',
          lastName: '不败',
        });
        const fullName1 = computed(() => {
          return person.firstName + '_' + person.lastName;
        });
        const fullName2 = computed({
          get() {
            return person.firstName + '_' + person.lastName;
          },
          set(val: string) {
            console.log(val);
            let name = val.split('_');
            person.firstName = name[0];
            person.lastName = name[1];
          }
        });
        const fullName3 = ref('');
        watch(person, ({firstName, lastName}) => {
          fullName3.value = firstName + lastName;
        }, {immediate: true});//immediate刚进页面的时候，即第一次也会触发

        // watch可以监听多个对象，但是监听非响应式的对象要用函数return的形式
        watch([() => person.firstName, () => person.firstName, fullName3], () => {
          console.log('=====');
        });

        const msg = ref('what the fish');
        const msg2 = ref('giao!!!');
        const count = ref(0);
        const obj = reactive({
          name: '小明',
          age: 18,
          cars: ['奔驰', '宝马', '奥迪'],
        });
        const user = reactive<any>(obj);//user:代理对象 obj：目标对象
        // const user = reactive({
        //   name: '小明',
        //   age: 18,
        //   cars: ['奔驰', '宝马', '奥迪'],
        // });

        // 方法
        function updateCount() {
          count.value++;
        }

        const updateUser = () => {
          user.gender = '男';
          delete user.name;
          // user.name = '小红';
          // user.age = 19;
        };
        const xxx = (val: string) => {
          msg.value += val;
        };
        return {
          msg,
          msg2,
          count,
          user,
          updateCount,
          updateUser,
          xxx,
          person,
          fullName1,
          fullName2,
          fullName3,
        };
      },
    }
);
